import(/* webpackMode: "eager", webpackExports: ["ReactQueryDevtools"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-devtools-virtual-34b7d7512e/0/cache/@tanstack-react-query-devtools-npm-5.59.0-f20d0b12df-19e186faa6.zip/node_modules/@tanstack/react-query-devtools/build/modern/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-virtual-5c7bfd1dba/0/cache/@tanstack-react-query-npm-5.59.0-4fd85dc5e2-035f1039a8.zip/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-virtual-5c7bfd1dba/0/cache/@tanstack-react-query-npm-5.59.0-4fd85dc5e2-035f1039a8.zip/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-virtual-5c7bfd1dba/0/cache/@tanstack-react-query-npm-5.59.0-4fd85dc5e2-035f1039a8.zip/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-virtual-5c7bfd1dba/0/cache/@tanstack-react-query-npm-5.59.0-4fd85dc5e2-035f1039a8.zip/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-virtual-5c7bfd1dba/0/cache/@tanstack-react-query-npm-5.59.0-4fd85dc5e2-035f1039a8.zip/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-virtual-5c7bfd1dba/0/cache/@tanstack-react-query-npm-5.59.0-4fd85dc5e2-035f1039a8.zip/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-virtual-5c7bfd1dba/0/cache/@tanstack-react-query-npm-5.59.0-4fd85dc5e2-035f1039a8.zip/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-virtual-5c7bfd1dba/0/cache/@tanstack-react-query-npm-5.59.0-4fd85dc5e2-035f1039a8.zip/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-virtual-5c7bfd1dba/0/cache/@tanstack-react-query-npm-5.59.0-4fd85dc5e2-035f1039a8.zip/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-virtual-5c7bfd1dba/0/cache/@tanstack-react-query-npm-5.59.0-4fd85dc5e2-035f1039a8.zip/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-virtual-5c7bfd1dba/0/cache/@tanstack-react-query-npm-5.59.0-4fd85dc5e2-035f1039a8.zip/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-virtual-5c7bfd1dba/0/cache/@tanstack-react-query-npm-5.59.0-4fd85dc5e2-035f1039a8.zip/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-virtual-5c7bfd1dba/0/cache/@tanstack-react-query-npm-5.59.0-4fd85dc5e2-035f1039a8.zip/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/next-virtual-a8c5709b84/0/cache/next-npm-14.2.20-903d2e69b2-a2e214762c.zip/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-primary\",\"adjustFontFallback\":true,\"preload\":true}],\"variableName\":\"primaryFont\"}");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/next-virtual-a8c5709b84/0/cache/next-npm-14.2.20-903d2e69b2-a2e214762c.zip/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Outfit\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-secondary\",\"adjustFontFallback\":true,\"preload\":true}],\"variableName\":\"secondaryFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/apps/magicsquare/components/layouts/Main/Main.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/apps/magicsquare/components/providers/GlobalModalsMagicsquare/GlobalModalsMagicsquare.tsx");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/apps/magicsquare/styles/global.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/AuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/GlobalModalsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalStateProvider"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/GlobalStateProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleAdSenseProvider"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/GoogleAdSenseProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/GoogleTagManagerProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MixPanelProvider"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/MixPanelProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/PasskeyProvider.tsx");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/socket/SocketProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/ToastProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/TonProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/TurnstileProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/UserAgentDetectProvider.tsx");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/Web3Provider/Web3Provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QueryProviderSsr","getQueryClient"] */ "/builds/magic.ai/frontend/magicsquare/libs/utilities/src/react-query/QueryProviderSsr.tsx");
