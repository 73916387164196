'use client';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import TagManager from 'react-gtm-module';

import Cookies from 'js-cookie';

import { GTM } from '@shared/config/gtm';

import { createSafeContext, useSafeContext } from '@utilities/context';

interface GoogleTagManagerProviderProps {
  children?: ReactNode;
}

interface ContextValue {
  tagManager: typeof TagManager;
  triggerAuthEvent: (userId: number, params: { isNewUser?: boolean }) => void;
}

const Context = createSafeContext<ContextValue>();

export const sendTagManagerBannerClick = ({
  bannerId,
  url,
}: {
  bannerId: string;
  url?: string;
}) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'gtm.bannerClick',
      banner: bannerId,
      urlFrom: url || window.location.href,
    },
  });
};

export const useTagManager = () => useSafeContext(Context);

const setLastStoredCampaign = (campaign: string) => {
  return Cookies.set('lastCampaign', campaign, { expires: 365 });
};

const getLastStoredCampaign = () => {
  return Cookies.get('lastCampaign');
};

const GoogleTagManagerProvider = ({ children }: GoogleTagManagerProviderProps) => {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const campaignName = searchParams.get('utm_campaign') || searchParams.get('campaign');

    if (campaignName) {
      setLastStoredCampaign(campaignName);
    }

    if (GTM.gtmId) {
      TagManager.initialize({
        gtmId: GTM.gtmId,
      });
    }
  }, []);

  const triggerAuthEvent: ContextValue['triggerAuthEvent'] = useCallback(
    (userId, { isNewUser }) => {
      const campaignName = getLastStoredCampaign();
      let eventName = null;

      if (isNewUser) {
        eventName = campaignName ? 'gtm.createAccountFromCampaign' : 'gtm.createAccount';
      } else if (campaignName) {
        eventName = 'gtm.loginFromCampaign';
      }

      if (eventName) {
        TagManager.dataLayer({
          dataLayer: {
            event: eventName,
            url: window.location.href,
            userId: userId,
            // NOTE: do not change name of the field it can affect google analytics
            ...(campaignName
              ? {
                  campaignName,
                }
              : {}),
          },
        });

        setLastStoredCampaign('');
      }
    },
    [],
  );

  const value = useMemo(() => {
    return {
      tagManager: TagManager,
      triggerAuthEvent,
    };
  }, [triggerAuthEvent]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default GoogleTagManagerProvider;
